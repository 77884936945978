<script lang="tsx">
import { defineComponentBaseUiInput } from '@core/app/components/base/ui/BaseUiInput.vue'
import { IconCross, IconEye, IconEyeCrossed } from '#components'
import { Transition } from 'vue'

export type BaseUiInputVariants = 'solid' | 'outlined'

export default defineComponentBaseUiInput<BaseUiInputVariants>({
    props: {
        variant: {
            default: 'outlined',
        },
    },
    slots: {
        clear: () => <IconCross size={19} style="stroke-width: 1.2" />,
        passwordToggle: ({ isVisible }) => <Transition mode="out-in">
            {isVisible ? <IconEyeCrossed class="icon" /> : <IconEye size={22} class="icon" />}
        </Transition>,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiInput" as *;

@include input {
    background: $vut-c-white;
    border: 1px solid transparent;
    gap: 0.75rem;

    @include set-padding(0.75rem, 1.25rem);
    @include vut-text;

    &:focus-within {
        border: 1px solid $vut-c-secondary;
    }
}

@include side-content {
    gap: 1rem;
}

@include input--variant('outlined') {
    border-radius: $vut-border-radius;
    border: hr-line();
}

@include input--disabled {
    @include disabled-input;
}

@include input--error {
    @include error-input;
}

@include placeholder {
    @include input-placeholder;
}

@include text {
    @include vut-text;
}

@include clear-button {
    padding: 0.125rem;
}

.icon {
    &.v-enter-active,
    &.v-leave-active {
        transition: transform 75ms $vut-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: scale(0.85);
    }
}

@include password-toggle-button {
    border-radius: 100vmax;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    margin: -0.25rem;
    margin-block: -0.5rem;

    transition: color $vut-trans-time $vut-timing;
}

</style>
