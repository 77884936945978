import { createLocalizedValidationMessage } from '@core-theme/app/utils/form'

export const zStringMediumLengthType = z.string().max(50)
export const zStringLongLengthType = z.string().max(100)
export const zStringShortLengthType = z.string().max(20)
export const zStringPostCodeType = z.string().max(10)
export const zStringDialCodeType = z.string().min(1)
export const zStringCountryIdType = z.string().min(1)
export const zStringPhoneType = z.string().min(1).max(30)
export const zStringMailType = z.string().min(1).max(74).email()
export const zStringNoteType = z.string().max(250)
export const zStringPassword = z.string().min(8).max(255)

export const zBooleanRequired = z.boolean().refine((val) => val === true, createLocalizedValidationMessage('_core_simploshop.zod.field_required'))
